import React, { createContext, ReactNode, useContext, useEffect } from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { HowToPlayModal } from '@/components/HowToPlayModal/HowToPlayModal';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

const HowToPlayModalContext = createContext({
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
});

type Props = {
  children: ReactNode;
};
export const HowToPlayModalProvider: React.FC<Props> = (props) => {
  const { children } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { value } = useLocalStorage<string>(StorageKeysEnum.onboarding);

  useEffect(() => {
    if (!value) {
      const timeout = setTimeout(() => onOpen(), 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [onOpen, value]);

  return (
    <HowToPlayModalContext.Provider value={{ isOpen, onOpen, onClose }}>
      {children}
      <HowToPlayModal />
    </HowToPlayModalContext.Provider>
  );
};

export const useHowToPlayModalContext = () => useContext(HowToPlayModalContext);
