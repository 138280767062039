import React, { SVGProps } from 'react';

export const WhistleIcon: React.FC<SVGProps<any>> = ({
  fill = 'white',
  fontSize = '32',
}) => {
  return (
    <svg
      fill='none'
      height={fontSize}
      viewBox='0 0 32 32'
      width={fontSize}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 8.41962L12.4403 4C12.4403 4 24.3903 10.2337 26.3384 11.4391C28.2864 12.6444 29.443 14.1237 29.8874 16.4675C30.4231 19.28 28.9986 21.9768 26.6366 23.4074C24.439 24.7345 21.1273 26.2686 19.5689 27.0112C17.767 27.8696 14.8814 28.5575 11.3323 25.5137C7.24754 22.0133 8.23982 16.1813 8.23982 16.1813L2 12.3766V8.41962Z'
        stroke={fill}
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M19.2529 24.3023C19.5816 23.2248 19.6729 22.0377 19.4537 20.8872C19.0093 18.5495 17.6153 16.6928 15.9047 15.8588L4.86169 9.94769'
        stroke={fill}
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M18.9295 15.1343L15.4352 13.2593'
        stroke={fill}
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M24.7981 12.6442L21.3038 10.7753'
        stroke={fill}
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M15.4352 13.259L21.3037 10.7753'
        stroke={fill}
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
      <path
        d='M18.9301 15.1282L24.7985 12.6445'
        stroke={fill}
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='1.4'
      />
    </svg>
  );
};
