import styled from 'styled-components';

export const SliderWrap = styled('div')<{ isDesktop: boolean }>(
  ({ isDesktop, theme }) => ({
    marginTop: theme.space(1),
    paddingInline: theme.space(2),
    ...(isDesktop && {
      maxWidth: '80%',
      marginInline: 'auto',
    }),
  }),
);

export const Title = styled('h1')<{ isDesktop: boolean }>(
  ({ isDesktop, theme }) => ({
    color: theme.colors.white,
    textAlign: 'center',
    fontSize: isDesktop ? '48px' : '24px',
    fontWeight: 400,
    padding: isDesktop ? theme.space(4) : theme.space(2),
  }),
);

export const Content = styled('div')<{ isDesktop: boolean }>(
  ({ isDesktop, theme }) => ({
    ...(isDesktop && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row-reverse',
      background: 'white',
      borderRadius: '40px',
      marginBottom: theme.space(3),
      marginInline: '10px',
      height: 'content-fit',
    }),
  }),
);

export const ImageBlock = styled('div')<{ isDesktop: boolean }>(
  ({ isDesktop, theme }) => ({
    margin: '0 auto',
    height: isDesktop ? 'auto' : '40vh',
    maxHeight: isDesktop ? 'auto' : '350px',
    ...(isDesktop && {
      padding: theme.space(0.4),
      flex: 1,
    }),

    img: {
      height: '100%',
      marginInline: 'auto',
      ...(isDesktop && {
        borderRadius: '0 40px 40px 0',
        width: '100%',
        height: 'auto',
      }),
    },
  }),
);

export const Text = styled('p')<{ isDesktop: boolean }>(
  ({ isDesktop, theme }) => ({
    marginBlock: theme.space(2),
    textAlign: 'center',
    fontWeight: 'normal',
    ...(isDesktop && {
      color: theme.colors.black,
      margin: `0 ${theme.space(2)}`,
      flex: 1,
      padding: '10px 0',
    }),
  }),
);

export const ButtonWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.space(6),
  display: 'flex',
  justifyContent: 'center',
}));

export const Dots = styled('ul')({
  li: {
    background: 'black',
    opacity: '0.5',
    transition: 'width 0.3s ease-in-out',
    width: '8px',
    height: '8px',
    borderRadius: '8px',
    button: {
      opacity: '0',
    },
  },
  'li.slick-active': {
    width: '30px',
    height: '8px',
    opacity: '1',
  },
});

const SliderButton = styled('div')({
  display: 'block !important',
  borderTop: '2px solid !important',
  borderRight: '2px solid !important',
  borderColor: 'white !important',
  '&::before': {
    color: 'transparent !important',
  },
});

export const NextButton = styled(SliderButton)<{ isHidden: boolean }>(
  ({ isHidden }) => ({
    transform: 'rotate(45deg) !important',
    display: isHidden ? 'none !important' : 'block',
  }),
);
export const PrevButton = styled(SliderButton)<{ isHidden: boolean }>(
  ({ isHidden }) => ({
    transform: 'rotate(-135deg) !important',
    display: isHidden ? 'none !important' : 'block',
  }),
);
