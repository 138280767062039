import React from 'react';

import { Box, Container, Grid, useTheme } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

// import { Advert } from '@/elements/Advert/Advert';
import { IHeaderNavigation, useNavLinks } from '@/hooks/useNavLinks.hook';

import { trackEvent } from '@/utils/analytics';
// import { useSettings } from '@/hooks/useSettings.hook';

type Props = {
  isHeaderShown: boolean;
};

const Navigation: React.FC<Props> = (props) => {
  const { isHeaderShown } = props;

  const theme = useTheme();

  const { navLinks } = useNavLinks();
  // const { isMobile } = useSettings();

  const handleClick = (item: IHeaderNavigation) => () => {
    trackEvent({
      button_text: item?.label,
      location: 'Streaks Navigation',
      action: `User clicks the '${item?.label}' button`,
      event: 'button_clicked',
    });

    item.onClick?.();
  };

  return (
    <Container
      backgroundColor={theme.colors.navigation.background}
      maxW='auto'
      px='0'
      w='100%'
      zIndex='10'
    >
      {/*{isMobile && <Advert height='75px' />}*/}
      <Grid autoFlow='column' h='48px' margin='0 auto' maxW='800px'>
        {navLinks.map((item, idx) => (
          <Box
            alignItems='center'
            as={Link}
            borderBottom='3px solid'
            borderColor={
              item.selected
                ? theme.colors.navigation.borderActive
                : theme.colors.navigation.border
            }
            color={theme.colors.navigation.text}
            display='flex'
            fontSize='16px'
            fontWeight='600'
            justifyContent='center'
            key={idx}
            onClick={handleClick(item)}
            opacity={item.selected ? 1 : 0.7}
            to={item.linkData?.to}
          >
            {item.label}
          </Box>
        ))}
      </Grid>
    </Container>
  );
};

export default Navigation;
