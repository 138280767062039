export enum RoutesEnum {
  home = '/',
  complete = '/complete',
  login = '/login',
  signup = '/signup',
  leaderboard = '/leaderboard',
  prizes = '/prizes',
  history = '/history',
  redirectCallback = '/redirect-callback',
  guide = '/how-to-play',
  rules = '/rules',
}
