import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { api } from '@/services/api.service';

import pickemSlice from '@/store/slices/pickem.slice';
import sidebarSlice from '@/store/slices/sidebar.slice';
import streakSlice from '@/store/slices/streak.slice';
import userSlice from '@/store/slices/user.slice';

const rootReducer = combineReducers({
  pickem: pickemSlice,
  streak: streakSlice,
  sidebar: sidebarSlice,
  user: userSlice,
  [api.reducerPath]: api.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
