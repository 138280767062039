export enum StorageKeysEnum {
  jwt = 'jwt',
  i18nextLng = 'i18nextLng',
  token = 'token',
  redirectURL = 'redirectURL',
  favourite_team_league_id = 'favourite_team_league_id',
  pickSelections = 'pickSelections',
  clickedURL = 'clickedURL',
  onboarding = 'onboarding',
}
