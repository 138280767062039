import { FC, useCallback } from 'react';

import {
  Checkbox,
  CheckboxProps,
  Flex,
  FlexProps,
  Text,
} from '@chakra-ui/react';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import {
  useLazyGetUserQuery,
  useSubscribeMarketingNotificationsMutation,
} from '@/services/api.service';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { SET_USER_INFO, selectUserInfo } from '@/store/slices/user.slice';

type NotificationCheckboxProps = FlexProps & Pick<CheckboxProps, 'variant'>;

export const NotificationCheckbox: FC<NotificationCheckboxProps> = (props) => {
  const { value: token } = useLocalStorage<string>(StorageKeysEnum.token);
  const dispatch = useAppDispatch();

  const { marketing_notifications_token, allow_marketing_notifications } =
    useAppSelector(selectUserInfo);

  const [triggerSubscribeNotifications, { isLoading }] =
    useSubscribeMarketingNotificationsMutation();
  const [triggerGetUser] = useLazyGetUserQuery();

  const updateSubscription = useCallback(async () => {
    triggerSubscribeNotifications({
      marketing_notifications_allow: !allow_marketing_notifications,
      token: marketing_notifications_token,
    })
      .unwrap()
      .then(({ success }) => {
        if (success) {
          triggerGetUser({ token })
            .unwrap()
            .then((userInfo) => dispatch(SET_USER_INFO(userInfo)));
        }
      })
      .catch(console.error);
  }, [
    allow_marketing_notifications,
    dispatch,
    marketing_notifications_token,
    token,
    triggerGetUser,
    triggerSubscribeNotifications,
  ]);

  const handleChangeCheckbox = async () => {
    updateSubscription();
  };

  return (
    <Flex direction='column' gap='10px' {...props}>
      <Checkbox
        isChecked={allow_marketing_notifications}
        isDisabled={isLoading}
        onChange={handleChangeCheckbox}
        variant={props.variant}
      >
        <Text color={props.color} fontSize='11px'>
          Receive in game communications from WTA Fantasy
        </Text>
      </Checkbox>
      <Text fontSize='12px' fontWeight='400'>
        All prize winners will be contacted via the registered WTA Unlocked
        contact details
      </Text>
    </Flex>
  );
};
