import { Image, Box } from '@chakra-ui/react';

import image from './ADBannerImage.png';

export const AdBanner = () => {
  return (
    <Box maxW={{ base: '100%', md: '630px' }} mx='auto'>
      <Image
        alt='ad-banner'
        m='0 auto'
        mb='24px'
        mt={{ base: 0, md: '24px' }}
        src={image}
      />
    </Box>
  );
};
