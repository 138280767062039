const PrimaryPurple = '#7814FF';
const PrimaryDarkPurple = '#0D003B';
const PrimaryDarkPurple40 = 'rgba(13,0,59,0.4)';
const PrimaryElectricYellow = '#E4F53B';
const Gradient01 = 'linear-gradient(180deg, #7814FF 0%, #0D003B 100%)';
const SecondaryGrass = '#0CB590';

const colors = {
  absoluteDark: '#000000',
  defaultGray: '#666666',
  appTextColor: '#FFFFFF',
  white: '#FFFFFF',
  PrimaryDarkPurple,
  PrimaryPurple,
  PrimaryElectricYellow,
  Gradient01,
  SecondaryGrass,

  toast: {
    success: '#009656',
    error: '#E22F2F',
    text: '#FFFFFF',
  },

  appBg: {
    primaryBg: '#292935',
    secondaryBg: '#FFFFFF',
  },
  header: {
    border: 'rgba(255,255,255, 0.3)',
    logo: '#FFFFFF',
    text: '#FFFFFF',
    primaryBg: '#292935',
    secondaryBg: '#A01313',
  },
  navbar: {
    bg: '#292935',
    text: '#FFFFFF',
    selected: '#F73535',
  },
  contestCard: {
    bg: '#FFFFFF',
    text: '#E22F2F',
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
  },
  cardSmall: {
    bg: '#292935',
    descriptionBg: '#F73535',
    text: '#FFFFFF',
    buttonBg: '#FFFFFF',
    buttonBgActive: '#FFFFFF',
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
    title: '#FFFFFF',
    description: '#FFFFFF',
  },
  tile: {
    bg: 'linear-gradient(251deg, #1CFF47 -12.7%, #25BD95 103.67%)',
    text: '#111111',
  },
  contestTile: {
    bg: '#33CFFF',
    text: '#111111',
    liveText: '#FFFFFF',
    liveBg: 'linear-gradient(99deg, #F73535 13.3%, #F2B60F 86.79%)',
  },
  userContestTile: {
    bg: '#3B3B54',
    text: '#FFFFFF',
    winBg: 'linear-gradient(251deg, #1CFF47 -12.7%, #25BD95 103.67%)',
    winText: '#292935',
  },
  timer: {
    bg: '#FFFFFF',
    text: '#19191C',
    image: '#666666',
    imageActive: '#E22F2F',
  },
  section: {
    title: '#FFFFFF',
    leftImage: {
      title: '#E22F2F',
      subtitle: '#E22F2F',
      description: '#E22F2F',
    },
  },
  carousel: {
    bullet: '#FFFFFF',
    bulletActive: '#FFFFFF',
  },
  langSwitcher: {
    bg: '#292935',
    icon: '#F73535',
    border: '#A01313',
    text: '#FFFFFF',
  },
  input: {
    bg: '#3B3B54',
    border: '#88B3FF',
    text: '#FFFFFF',
    placeholder: '#8A8AB2',
    invalid: '#E22F2F',
    cancel: '#D9D9D9',
    hint: '#D9D9D9',
    success: '#009656',
    eye: '#D9D9D9',
  },
  checkbox: {
    label: '#FFFFFF',
    bg: '#3B3B54',
    icon: '#08F9AE',
  },
  signup: {
    title: '#FFFFFF',
    text: '#FFFFFF',
  },
  login: {
    title: '#FFFFFF',
    text: '#FFFFFF',
  },
  profile: {
    title: '#FFFFFF',
    text: '#FFFFFF',
  },
  resetPassword: {
    title: '#FFFFFF',
    text: '#FFFFFF',
  },
  forgotPassword: {
    title: '#FFFFFF',
    text: '#FFFFFF',
    success: '#009656',
  },
  unsubscribe: {
    title: '#FFFFFF',
    text: '#FFFFFF',
    iconButton: '#FFFFFF',
  },
  countrySelect: {
    bg: '#3B3B54',
    border: 'transparent',
    text: '#FFFFFF',
    menuBg: '#3B3B54',
    placeholder: '#8A8AB2',
    arrow: '#8A8AB2',
  },
  sidebar: {
    bg: '#fff',
    close: '#000',
    logo: '#7814FF',
    text: '#7814FF',
    overlay: 'rgba(0, 0, 0, 0.40)',
  },
  button: {
    defaultText: '#FFFFFF',
    primary: {
      bg: 'linear-gradient(251deg, #67F482 -12.7%, #3BBB99 103.67%)',
      disabledBg: 'linear-gradient(180deg, #7BD46D 0%, #529248 100%)',
      text: '#FFFFFF',
      disabledText: 'rgba(7, 60, 28, 0.3)',
      activeBg: PrimaryPurple,
    },
    secondary: {
      bg: 'transparent',
      border: '#FFFFFF',
      disabledBg: '#6A6A6A',
      text: '#FFFFFF',
      activeBg: '#2A2A2A',
      activeText: '#19191C',
    },
    outline: {
      border: '#1CFF47',
    },
  },
  navigation: {
    border: 'transparent',
    borderActive: '#7814FF',
    text: '#FFFFFF',
    background: '#0D003B',
  },
  desktopSideNavbar: {
    bg: '#292935',
    text: '#FFFFFF',
    textActive: '#F73535',
  },
  stepper: {
    background: '#19191C',
    activeColor: '#fff',
    activeBgColor: PrimaryDarkPurple,
    correct: '#0CB590',
    incorrect: '#F73535',
    color: 'white',
    backgroundColor: PrimaryDarkPurple40,
    grey: 'rgba(241, 243, 245, 0.60)',
  },
  mobileStepper: {
    mainBg: '#19191C',
    buttonBg: '#2B1D5C',
  },
  home: {
    questionBlockBg: '#173476',
    activeText: '#33CFFF',
    buttonText: 'white',
  },
  prizes: {
    rulesBg: PrimaryDarkPurple,
    prize: '#08F9AE',
  },

  playQuestion: {
    hintPrizeText: '#33CFFF',
    winPrizeText: '#08F9AE',
    bg: PrimaryDarkPurple,
    eliminatedText: '#F73535',
  },

  playQuestionAnswered: {
    title: PrimaryDarkPurple,
    text: '#FFFFFF',
    prize: '#08F9AE',
    prizeBg: '#173476',
    optionBg: '#88B3FF',
    optionText: '#19191C',
  },

  pickSaved: {
    hintNoPrize: '#33CFFF',
  },

  history: {
    text: '#0D003B',
    background: '#FFFFFF',
    questionBorder: '#7814FF',
    incorrect: '#E9634E',
    correct: '#0CB590',
    incorrectBorder: '#DC143C',
    correctBorder: '#0CB590',
    correctOptionBackground: '#0CB590',
    defaultBorder: '#0D003B',
    defaultOptionBackground: '#0D003B',
  },

  gradientCasino: 'linear-gradient(99deg, #173476 13.21%, #F73535 86.79%)',
  answerButton: {
    // bg: '#3B3B54',
    bg: '#fff',
    disabledBg: '#5c5c5c',
    activeBg: PrimaryPurple,
    correctBg: '#0CB590',
    incorrectBg: '#DC143C',
    dark_text: PrimaryDarkPurple,
    white_text: '#ffffff',
    disabled_text: '#bfbfbf',
    border_color: PrimaryPurple,
    iconBg: 'rgba(95, 95, 122, 0.50)',
    activeIconBg: 'rgba(97, 146, 216, 0.50)',
  },

  modal: {
    bg: '#FFFFFF',
    overlayBg: 'rgba(0, 0, 0, 0.40)',
    title: '#111111',
    text: '#FFFFFF',
  },

  table: {
    bg: '#292935',
    nonPairRow: '#292935',
    pairRow: '#1B1B1B',
    currentUserBg: '#316EF7',
    text: '#FFFFFF',
  },
};

export default colors;
