import { createSlice } from '@reduxjs/toolkit';

import {
  NormalizedPicksSelection,
  Pickem,
  PicksSelection,
} from '@/types/domain';

import { getNormalizedSelections } from '@/utils/selections';

interface IPickemState {
  isJwtLoading: boolean;
  pickem: Pickem | null;
  selections: PicksSelection[];
  initialSelections: PicksSelection[];
  normalizedSelections: NormalizedPicksSelection;
  initialNormalizesSelections: NormalizedPicksSelection;
}

const initialState: IPickemState = {
  isJwtLoading: true,
  pickem: null,
  selections: [],
  normalizedSelections: {},
  initialSelections: [],
  initialNormalizesSelections: {},
};

const pickemSlice = createSlice({
  name: 'pickem',
  initialState,
  reducers: {
    SET_IS_JWT_LOADING(state, action) {
      state.isJwtLoading = action.payload;
    },
    SET_CURRENT_PICKEM(state, action) {
      state.pickem = action.payload;
    },
    SET_INITIAL_SELECTIONS(state, action) {
      state.selections = action.payload.selections;
      state.initialSelections = action.payload.selections;
      state.normalizedSelections = action.payload.normalizedSelections;
      state.initialNormalizesSelections = action.payload.normalizedSelections;
    },
    SET_SELECTION(
      state,
      { payload: { question_id, option_id, custom_value } },
    ) {
      const selections = [...state.selections];

      const foundIndex = selections.findIndex(
        (item) => item.question_id === question_id,
      );
      if (foundIndex === -1) {
        const updatedSelections = [
          ...selections,
          { question_id, option_id, custom_value },
        ];

        state.selections = updatedSelections;
        state.normalizedSelections = getNormalizedSelections(updatedSelections);
        return;
      }

      const updatedSelections = [...selections].map((item) =>
        item.question_id === question_id
          ? { question_id, option_id, custom_value }
          : item,
      );

      state.selections = updatedSelections;
      state.normalizedSelections = getNormalizedSelections(updatedSelections);
    },
  },
});

export default pickemSlice.reducer;
export const {
  SET_IS_JWT_LOADING,
  SET_CURRENT_PICKEM,
  SET_SELECTION,
  SET_INITIAL_SELECTIONS,
} = pickemSlice.actions;
