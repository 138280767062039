import React, { useCallback, useMemo } from 'react';

import { Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LinkProps, useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';

import CircleInfo from '@/assets/icons/circle-info-outline.svg';

import { useHowToPlayModalContext } from '@/components/HowToPlayModal/HowToPlayModalContext';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';
import { TimePeriodEnum } from '@/enums/timePeriod.enum';

import { useLang } from '@/hooks/useLang.hook';

import { StadiumIcon } from '@/icons/Stadium.icon';
import { WhistleIcon } from '@/icons/Whistle.icon';

import { GAMEZONE_URL } from '@/utils/constants';

import { useSettings } from './useSettings.hook';

export interface IHeaderNavigation {
  label: string;
  icon?: React.ReactNode;
  selected?: boolean;
  linkData?: LinkProps;
  onClick?: () => void;
  hide?: boolean;
}

export const useNavLinks = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { lang } = useLang();
  const { isDesktop } = useSettings();

  const { onOpen } = useHowToPlayModalContext();

  const createLinkToGZ = useCallback(
    (pathname?: string | undefined) => {
      if (pathname) {
        return `${GAMEZONE_URL}/${pathname}?lang=${lang}&redirectURL=${GAMEZONE_URL}/${pathname}`;
      }
      return `${GAMEZONE_URL}?lang=${lang}&redirectURL=${GAMEZONE_URL}`;
    },
    [lang],
  );

  const linkToGZ = createLinkToGZ();

  const desktopNavLinks = useMemo<IHeaderNavigation[]>(
    () =>
      [
        {
          label: t(tKeys.play),
          selected: pathname === RoutesEnum.home,
          linkData: {
            to: RoutesEnum.home,
          },
        },
        {
          label: t(tKeys.leaderboard),
          selected: pathname === RoutesEnum.leaderboard,
          linkData: {
            to: `${RoutesEnum.leaderboard}?${SearchParamsEnum.period}=${TimePeriodEnum.allTime}`,
          },
        },
        {
          label: t(tKeys.howToPlay),
          onClick: onOpen,
          hide: !isDesktop,
        },
        {
          label: t(tKeys.history),
          selected: pathname === RoutesEnum.history,
          linkData: {
            to: RoutesEnum.history,
          },
        },
      ].filter(({ hide }) => !hide),
    [isDesktop, onOpen, pathname, t],
  );

  const navLinks = useMemo<IHeaderNavigation[]>(
    () => desktopNavLinks,
    [desktopNavLinks],
  );

  const sidebarNavLinks = useMemo(
    () => [
      {
        label: t(tKeys.play),
        icon: <WhistleIcon />,
        renderIcon: (props: any) => <WhistleIcon {...props} />,
        selected: pathname === RoutesEnum.home,
        linkData: {
          to: RoutesEnum.home,
        },
      },
      {
        label: t(tKeys.gamezone),
        icon: <StadiumIcon />,
        renderIcon: (props: any) => <StadiumIcon {...props} />,
        selected: false,
        linkData: {
          to: linkToGZ,
        },
      },
      {
        label: t(tKeys.rules),
        icon: <StadiumIcon />,
        renderIcon: () => <Image alt='info' src={CircleInfo} />,
        selected: pathname === RoutesEnum.rules,
        linkData: {
          to: RoutesEnum.rules,
        },
      },
    ],
    [linkToGZ, t, pathname],
  );

  return { navLinks, sidebarNavLinks, linkToGZ };
};
