import { FC, PropsWithChildren, useEffect } from 'react';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { useLazyGetUserQuery } from '@/services/api.service';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectUserInfo, SET_USER_INFO } from '@/store/slices/user.slice';

export const UserInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { sso_user_id } = useAppSelector(selectUserInfo);
  const [triggerGetUser] = useLazyGetUserQuery();
  const { value: token } = useLocalStorage<string>(StorageKeysEnum.token);

  useEffect(() => {
    if (token) {
      triggerGetUser({ token })
        .unwrap()
        .then((userInfo) => dispatch(SET_USER_INFO(userInfo)))
        .catch(console.error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (sso_user_id) {
      window.xtremepush('set', 'user_id', sso_user_id);
    }
  }, [sso_user_id]);

  return <>{children}</>;
};
