import React, { useEffect } from 'react';

import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

import { useHowToPlayModalContext } from '@/components/HowToPlayModal/HowToPlayModalContext';
import { Onboarding } from '@/components/Onboarding';

import { useSettings } from '@/hooks/useSettings.hook';

import { trackEvent } from '@/utils/analytics';

export const HowToPlayModal: React.FC = () => {
  const { isOpen, onClose } = useHowToPlayModalContext();
  const { isMobile } = useSettings();

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        location: 'Streaks Modal',
        action: "User views the 'Steaks' modal",
        event: 'steaks_modal_viewed',
      });
    }
  }, [isOpen]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent maxWidth='600px' padding={isMobile ? '16px' : '24px'}>
        <Onboarding onClose={onClose} />
      </ModalContent>
    </Modal>
  );
};
