import React, { PropsWithChildren } from 'react';

import { Box } from '@chakra-ui/react';

import BgImage from '@/assets/images/background.png';

export const GlobalBackground: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <Box
      backgroundAttachment='fixed'
      backgroundImage={BgImage}
      backgroundPosition='center'
      backgroundRepeat='no-repeat'
      backgroundSize='cover'
      height='auto'
      minHeight='100vh'
      width='100%'
    >
      {children}
    </Box>
  );
};
