import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/store';

export interface ISidebarSliceState {
  isSidebarOpen: boolean;
}

const initialState = {
  isSidebarOpen: false,
} as ISidebarSliceState;

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    SET_SIDEBAR_OPEN(state) {
      state.isSidebarOpen = true;
    },
    SET_SIDEBAR_CLOSE(state) {
      state.isSidebarOpen = false;
    },
  },
});

export const selectSidebar = (state: RootState) => state.sidebar;

export default sidebarSlice.reducer;
export const { SET_SIDEBAR_OPEN, SET_SIDEBAR_CLOSE } = sidebarSlice.actions;
