import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { ApiTagsEnum } from '@/enums/apiTags.enum';
import { TimePeriodEnum } from '@/enums/timePeriod.enum';

import {
  GetOnePickemResponse,
  GetPickemsResponse,
  LeaderboardParams,
  LeaderboardResponse,
  LoginReqBody,
  LoginResponse,
  MakeSelectionsReqBody,
  MakeSelectionsResponse,
  OnboardingSlotResponse,
  RulesResponse,
  UserLeaderboardPositionRes,
} from '@/types/domain';

import { baseQueryWithRedirect } from '@/utils/api';
import {
  AUTH_API_URL,
  CLIENT_CODE,
  GAME_CODE,
  LOW6_CLIENTS_API_URL,
  TENANT,
} from '@/utils/constants';

export const api = createApi({
  reducerPath: 'mainApi',
  tagTypes: Object.values(ApiTagsEnum),
  baseQuery: baseQueryWithRedirect,
  endpoints: (builder) => ({
    getPickemsList: builder.query<GetPickemsResponse, any>({
      query: () => '/pickems?offset=0&limit=10',
      providesTags: [ApiTagsEnum.PICKEMS],
    }),
    getOnePickem: builder.query<GetOnePickemResponse, { id: string }>({
      query: ({ id }) => `pickems/${id}`,
      providesTags: [ApiTagsEnum.PICKEMS],
    }),
    generateJWTToken: builder.mutation<LoginResponse, LoginReqBody>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
        headers: {
          'X-TENANT-ID': TENANT,
        },
      }),
    }),
    makeSelections: builder.mutation<
      MakeSelectionsResponse,
      MakeSelectionsReqBody
    >({
      query: ({ id, selectionsData: selections }) => ({
        url: `/pickems/${id}/selections`,
        method: 'POST',
        body: { selections },
      }),
      invalidatesTags: [ApiTagsEnum.PICKEMS],
    }),
    resetSelections: builder.mutation<{ success: boolean }, { id: number }>({
      query: ({ id }) => ({
        url: `/pickems/${id}/reset-selections`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagsEnum.PICKEMS],
    }),
    getOnboardingSlots: builder.query<OnboardingSlotResponse, void>({
      query: () => ({
        url: `${LOW6_CLIENTS_API_URL}/onboarding-slot/${CLIENT_CODE}/${GAME_CODE}`,
      }),
    }),
    getLeaderboard: builder.query<LeaderboardResponse, LeaderboardParams>({
      query: ({ pickem_id, offset, limit, period }) => ({
        url:
          !period || period === TimePeriodEnum.allTime
            ? `/leaderboard/pickem/${pickem_id}?offset=${offset}&limit=${limit}`
            : `/leaderboard/pickem/${pickem_id}/active?offset=${offset}&limit=${limit}`,
      }),
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}-${queryArgs.period}`;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      },
      providesTags: (result) =>
        (result?.leaderboard || []).map((x) => ({
          type: ApiTagsEnum.GET_LEADERBOARD,
          id: x.user_id,
        })),
    }),
    getUserLeaderboardPosition: builder.query<
      UserLeaderboardPositionRes,
      { pickem_id: number; period: string | null }
    >({
      query: ({ pickem_id, period }) => ({
        url:
          !period || period === TimePeriodEnum.allTime
            ? `/pickem-leaderboard/${pickem_id}/user`
            : `/pickem-leaderboard/${pickem_id}/user/active`,
      }),
    }),
    getRules: builder.query<RulesResponse, { lang: string; pickemId: number }>({
      query: ({ lang, pickemId }) => ({
        url: `page/pickem-${pickemId}?lang=${lang}`,
      }),
    }),
    getUser: builder.query<Record<string, unknown>, { token: string }>({
      query: ({ token }) => ({
        url: `${AUTH_API_URL}/user/${token}`,
      }),
      providesTags: [ApiTagsEnum.GET_USER],
    }),
    subscribeMarketingNotifications: builder.mutation<
      { success: boolean },
      { marketing_notifications_allow: boolean; token: string }
    >({
      query: (body) => ({
        url: `${AUTH_API_URL}/user/notification-preferences`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useGetPickemsListQuery,
  useGetOnePickemQuery,
  useGenerateJWTTokenMutation,
  useMakeSelectionsMutation,
  useGetOnboardingSlotsQuery,
  useGetLeaderboardQuery,
  useGetUserLeaderboardPositionQuery,
  useGetRulesQuery,
  useLazyGetUserQuery,
  useSubscribeMarketingNotificationsMutation,
  useResetSelectionsMutation,
} = api;
