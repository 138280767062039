// import { useMemo } from 'react';

import { matchPath, useLocation } from 'react-router-dom'; // useParams

import { DesktopHeader } from '@/components/Layout/Header/DesktopHeader';
import { MobileHeader } from '@/components/Layout/Header/MobileHeader';

import { RoutesEnum } from '@/enums/routes.enum';

import { useSettings } from '@/hooks/useSettings.hook';

import { IS_HEADER_HIDDEN } from '@/utils/constants';

export const HEADER_HEIGHT = '160px';

export const Header = () => {
  const { isDesktop } = useSettings();
  const { pathname } = useLocation();
  // const { id } = useParams();

  // const tertiaryHeaderPages = useMemo(
  //   () =>
  //     pathname ===
  //       addIdToRoute(RoutesEnum.friendsLeaderboardSettingsSpecific, id) ||
  //     pathname === addIdToRoute(RoutesEnum.friendsLeaderboardSpecific, id) ||
  //     pathname ===
  //       addIdToRoute(RoutesEnum.friendsLeagueShareCodeSpecific, id) ||
  //     pathname === addIdToRoute(RoutesEnum.pickemsLeaderboardSpecific, id),
  //   [pathname, id],
  // );

  const withoutBackButton = [
    RoutesEnum.home,
    RoutesEnum.leaderboard,
    RoutesEnum.history,
  ].some((route) => matchPath(route, pathname));

  if (IS_HEADER_HIDDEN) return null;

  // Desktop Header
  if (isDesktop) return <DesktopHeader />;

  // if (tertiaryHeaderPages) return null;
  // Mobile Header
  return <MobileHeader {...{ withoutBackButton }} />;
};
