import React from 'react';

import { Box, Flex, Grid, Image, Text, useTheme } from '@chakra-ui/react';
import { BsArrowLeft } from 'react-icons/bs';
import { NavLink, useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import OlyStreak from '@/assets/icons/olystreak.svg';

import ArrowButton from '@/elements/Buttons/ArrowButton';

import { RoutesEnum } from '@/enums/routes.enum';

import { useNavLinks } from '@/hooks/useNavLinks.hook';
import { useSettings } from '@/hooks/useSettings.hook';

import { IS_HEADER_HIDDEN } from '@/utils/constants';

export const SidebarNavbar: React.FC = () => {
  const { t } = useTheme();
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useSettings();
  const { sidebarNavLinks } = useNavLinks();

  const isSecondaryPage = false;

  if (!IS_HEADER_HIDDEN) {
    return null;
  }

  return (
    <Flex
      align='center'
      bgColor={theme.colors.desktopSideNavbar.bg}
      color='white'
      flexDirection={isMobile ? 'column' : 'row'}
      justify='center'
      sx={
        !isMobile
          ? {
              height: '100vh',
              maxH: '800px',
              top: '0',
              left: '0',
              position: 'fixed',
              width: '112px',
            }
          : undefined
      }
    >
      {isSecondaryPage && (
        <ArrowButton
          fontSize='14px'
          justifySelf='start'
          leftIcon={<BsArrowLeft size='24' />}
          onClick={() => navigate(RoutesEnum.home)}
          pos='absolute'
          top='10px'
        >
          {t(tKeys.back)}
        </ArrowButton>
      )}
      {
        <Flex
          align='center'
          as={NavLink}
          justify='center'
          p='10px'
          position={isMobile ? 'relative' : 'absolute'}
          to={RoutesEnum.home}
          top={isMobile ? '10px' : '40px'}
        >
          <Image alt='' src={OlyStreak} w='140px' />
        </Flex>
      }
      <Grid
        color='white'
        gap='10px'
        sx={
          !isMobile
            ? { gridTemplateRows: `repeat(${sidebarNavLinks.length}, 1fr)` }
            : { gridTemplateColumns: `repeat(${sidebarNavLinks.length}, 1fr)` }
        }
      >
        {sidebarNavLinks.map((item) => (
          <Flex
            align='center'
            as={NavLink}
            color={
              item.selected
                ? theme.colors.desktopSideNavbar.textActive
                : theme.colors.desktopSideNavbar.text
            }
            justify='center'
            key={item.linkData.to as string}
            {...item?.linkData}
          >
            <Flex
              align='center'
              direction='column'
              justify='center'
              padding='10px'
            >
              {item?.renderIcon && (
                <Box>
                  {item?.renderIcon({
                    fill: 'white',
                  })}
                </Box>
              )}
              <Text fontSize='12px' mt='5px' textAlign='center'>
                {item?.label}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Grid>
    </Flex>
  );
};
