import EST from '@/assets/icons/countries/EST.svg';
import FRN from '@/assets/icons/countries/FRN.png';
import GBR from '@/assets/icons/countries/GBR.svg';
import LAT from '@/assets/icons/countries/LAT.svg';
import RUS from '@/assets/icons/countries/RUS.png';
import SVK from '@/assets/icons/countries/SVK.svg';

import en from '@/i18n/locales/en/translation.json';
import es from '@/i18n/locales/es/translation.json';
import lt from '@/i18n/locales/lt/translation.json';
import rus from '@/i18n/locales/rus/translation.json';

import { ENABLED_LANGUAGES } from '@/utils/constants';

export const LANGUAGES = {
  en: 'EN',
  es: 'ES',
  fr: 'FR',
  lt: 'LT',
  sl: 'SL',
  rus: 'RU',
} as const;

export const DEFAULT_LANGUAGE = en;

export type LanguageCodeType = keyof typeof LANGUAGES;
export type LanguageNameType = (typeof LANGUAGES)[LanguageCodeType];

export const enabledLanguages = (ENABLED_LANGUAGES?.split(',').filter((lang) =>
  Object.keys(LANGUAGES).includes(lang),
) as LanguageCodeType[]) ?? ['en'];

export const resources = {
  en: { translation: en },
  es: { translation: es },
  lt: { translation: lt },
  rus: { translation: rus },
};

export type TFunc = (key: string) => string;
export type tKeysUnion = keyof typeof DEFAULT_LANGUAGE;
export type tKeysType = { [key in tKeysUnion]: tKeysUnion };

export const tKeys = Object.fromEntries(
  Object.keys(en).map((key) => [key, key]),
) as tKeysType;

export const getLanguageNameByCode = (
  code: LanguageCodeType,
): LanguageNameType => LANGUAGES[code] ?? 'EN';

export const countryFlagByCode = (code: LanguageCodeType) => {
  switch (code.toLowerCase()) {
    case 'es':
      return EST;
    case 'lt':
      return LAT;
    case 'sl':
      return SVK;
    case 'rus':
      return RUS;
    case 'fr':
      return FRN;
    case 'en':
    default:
      return GBR;
  }
};
