import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/store';

export interface IUSerSliceState {
  userId: number;
  email: string;
  username: string;
  marketing_notifications_token: string;
  allow_marketing_notifications: boolean;
  country: string;
  sso_user_id: string;
}

const initialState = {
  user: {} as IUSerSliceState,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SET_USER_INFO(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

const selectUserInfo = createSelector(
  (state: RootState) => state.user,
  (user) => user.user,
);

export { selectUserInfo };

export default userSlice.reducer;
export const { SET_USER_INFO } = userSlice.actions;
