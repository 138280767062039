const config = window.config;

if (!config) {
  window.config = {
    REACT_APP_ENABLED_LANGUAGES: process.env.REACT_APP_ENABLED_LANGUAGES,
    REACT_APP_IS_HEADER_HIDDEN: process.env.REACT_APP_IS_HEADER_HIDDEN,
    REACT_APP_GAMEZONE_URL: process.env.REACT_APP_GAMEZONE_URL,
    REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    REACT_APP_AUTH_API_URL: process.env.REACT_APP_AUTH_API_URL,
    REACT_APP_LOW6_CLIENTS_API_URL: process.env.REACT_APP_LOW6_CLIENTS_API_URL,
    REACT_APP_CLIENT_CODE: process.env.REACT_APP_CLIENT_CODE,
    REACT_APP_GAME_CODE: process.env.REACT_APP_GAME_CODE,
    REACT_APP_TENANT: process.env.REACT_APP_TENANT,
    REACT_APP_FEATURE_NON_LOGIN_ENABLED:
      process.env.REACT_APP_FEATURE_NON_LOGIN_ENABLED,
    REACT_APP_BANNER_BASE_URL: process.env.REACT_APP_BANNER_BASE_URL,
    REACT_APP_PICKEM_ID: process.env.REACT_APP_PICKEM_ID,
    NODE_ENV: process.env.NODE_ENV,
  };
}

export const ENABLED_LANGUAGES = window.config
  .REACT_APP_ENABLED_LANGUAGES as string;

export const IS_HEADER_HIDDEN =
  window.config.REACT_APP_IS_HEADER_HIDDEN === 'true';

export const GAMEZONE_URL = window.config.REACT_APP_GAMEZONE_URL as string;

export const API_BASE_URL = window.config.REACT_APP_API_BASE_URL as string;

export const AUTH_API_URL =
  process.env.REACT_APP_AUTH_API_URL ||
  'https://low6-wta-users-dev.azurewebsites.net';

export const LOW6_CLIENTS_API_URL = window.config
  .REACT_APP_LOW6_CLIENTS_API_URL as string;

export const CLIENT_CODE = window.config.REACT_APP_CLIENT_CODE as string;

export const GAME_CODE = window.config.REACT_APP_GAME_CODE as string;

export const TENANT = window.config.REACT_APP_TENANT as string;

export const FEATURE_NON_LOGIN_ENABLED =
  process.env.REACT_APP_FEATURE_NON_LOGIN_ENABLED === 'true';

export const BANNER_BASE_URL = window.config
  .REACT_APP_BANNER_BASE_URL as string;

export const PICKEM_ID = window.config.REACT_APP_PICKEM_ID;

export const IS_DEV_MODE = window.config.NODE_ENV === 'development';
