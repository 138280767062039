import { NormalizedPicksSelection, PicksSelection } from '@/types/domain';

export const getNormalizedSelections = (
  selections: PicksSelection[],
): NormalizedPicksSelection => {
  return selections.reduce((result, selection) => {
    result[selection.question_id] = selection;
    return result;
  }, {} as NormalizedPicksSelection);
};
