import React from 'react';

import './App.css';
import { Route, Routes } from 'react-router-dom';

import AppLayout from '@/AppLayout';
import { appRoutes } from '@/routes';

import Layout from '@/components/Layout';

function App() {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route element={<Layout />}>
          {appRoutes.map((route) => (
            <Route element={route.element} key={route.path} path={route.path} />
          ))}
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
