import { Box, Flex, Grid, Icon, Image, Link, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as ProfileIcon } from '@/assets/icons/user-profile.svg';
import bgImage from '@/assets/images/desktop-header-bg.jpeg';
import logoPurple from '@/assets/images/logo_purple.png';

import { GamezoneButton } from '@/components/Layout/Header/GamezoneButton';
import { RulesButton } from '@/components/Layout/Header/RulesButton';

import { Logo } from '@/elements/Logo';

import { useAppSelector } from '@/store/hooks';
import { selectUserInfo } from '@/store/slices/user.slice';

import colors from '@/theme/foundations/colors';

export const DesktopHeader = () => {
  const { username } = useAppSelector(selectUserInfo);

  return (
    <>
      <Grid as='header' templateColumns='1fr  1fr' width='100%'>
        <Flex align='start' justify='flex-start'>
          <Flex align='center' p='0 24px'>
            <Link href='https://www.wtatennis.com/unlocked'>
              <Image h='80px' src={logoPurple} />
            </Link>
          </Flex>
        </Flex>

        <Flex
          align='center'
          color={colors.PrimaryDarkPurple}
          justify='flex-end'
          pr='24px'
        >
          {username && (
            <Flex align='start' justify='flex-end'>
              <Flex align='center' gap='8px' p='16px 24px'>
                <Icon as={ProfileIcon} h='32px' w='32px' />
                <Text fontSize='16px' fontWeight='700'>
                  {username}
                </Text>
              </Flex>
            </Flex>
          )}

          <GamezoneButton />
          <RulesButton />
        </Flex>
      </Grid>

      <Box h='170px' position='relative'>
        <Box
          backgroundImage={`url(${bgImage})`}
          backgroundSize='cover'
          h='100%'
          position='absolute'
          w='100%'
          zIndex={1}
        />
        <Box
          background='linear-gradient(180deg, #7814FF 0%, #0D003B 100%)'
          h='calc(100% + 1px)'
          opacity={0.9}
          position='absolute'
          w='100%'
          zIndex={2}
        />
        <Box h='100%' position='absolute' w='100%' zIndex={3}>
          <Flex justify='center' margin='0 auto'>
            <Link as={RouterLink} to='/'>
              <Logo />
            </Link>
          </Flex>
          <Text
            color='PrimaryElectricYellow'
            fontFamily='Roc Grotesk, sans-serif'
            fontSize='62px'
            fontWeight='750'
            lineHeight='100%'
            textAlign='center'
          >
            STREAKS
          </Text>
        </Box>
      </Box>
    </>
  );
};
