import theme from '@/theme';

import { OptionStatusUnion, PrizesBreakdownType } from '@/types/domain';

import { GAMEZONE_URL } from '@/utils/constants';

export const linkToGZ = (lang: string) => `${GAMEZONE_URL}?lang=${lang}`;

export const bgColorByOptionStatus = {
  correct: theme.colors.answerButton.correctBg,
  incorrect: theme.colors.answerButton.incorrectBg,
  selection: theme.colors.answerButton.activeBg,
  selected: theme.colors.answerButton.activeBg,
  none: theme.colors.answerButton.bg,
  disabled: theme.colors.answerButton.disabledBg,
} as Record<OptionStatusUnion, string>;

export const textColorByOptionStatus = {
  correct: theme.colors.answerButton.dark_text,
  incorrect: theme.colors.answerButton.dark_text,
  selection: theme.colors.answerButton.white_text,
  selected: theme.colors.answerButton.white_text,
  none: theme.colors.answerButton.dark_text,
  disabled: theme.colors.answerButton.white_text,
} as Record<OptionStatusUnion, string>;

export const calcDayStreakToEarnPrize = (
  prizesBreakdown: PrizesBreakdownType[] | undefined,
) => {
  let counter = 0;
  (prizesBreakdown || [])?.map((x) => {
    if (!x.prizeText) counter++;
    return;
  });
  return counter;
};

export const TOKEN_LOGOUT = 'logout';
