import { FC } from 'react';

import { Box, Flex, Grid, Link, GridItem, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { Info } from '@/assets/icons/Info';
import { ReactComponent as ArrowLeftIcon } from '@/assets/icons/arrow-left-lg.svg';
import { ReactComponent as KebabIcon } from '@/assets/icons/kebab.svg';
import HeaderBg from '@/assets/images/mobile_header_bg.png';

import { useHowToPlayModalContext } from '@/components/HowToPlayModal/HowToPlayModalContext';

import { Logo } from '@/elements/Logo';

import { RoutesEnum } from '@/enums/routes.enum';

import { SET_SIDEBAR_OPEN } from '@/store/slices/sidebar.slice';

import { getNavigationHelperText } from '@/utils/navigation';

export const MobileHeader: FC<{
  withoutBackButton: boolean;
}> = ({ withoutBackButton }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { onOpen } = useHowToPlayModalContext();

  const mobileHeaderHelperText = getNavigationHelperText(pathname);

  return (
    <Grid
      alignItems='center'
      as='header'
      background={`url(${HeaderBg}) center / cover`}
      padding='16px'
      templateColumns='1fr auto 1fr'
    >
      <GridItem as={Flex}>
        {!withoutBackButton ? (
          <Box
            as={'button'}
            onClick={() => navigate(RoutesEnum.home)}
            p='6.67px'
          >
            <ArrowLeftIcon />
          </Box>
        ) : (
          <Box
            as={'button'}
            height='32px'
            onClick={() => dispatch(SET_SIDEBAR_OPEN())}
            width='32px'
          >
            <KebabIcon />
          </Box>
        )}
      </GridItem>

      <GridItem>
        {withoutBackButton ? (
          <Link as={RouterLink} to='/'>
            <Logo />
          </Link>
        ) : (
          <Box alignItems='center' display='flex' height='81px'>
            <Text fontSize='20px'>{mobileHeaderHelperText}</Text>
          </Box>
        )}
      </GridItem>

      {withoutBackButton && (
        <GridItem as={Flex} justifyContent='flex-end'>
          <Box onClick={onOpen} sx={{ transform: 'rotate(180deg)' }}>
            <Info />
          </Box>
        </GridItem>
      )}
    </Grid>
  );
};
