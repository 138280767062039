import { RoutesEnum } from '@/enums/routes.enum';

export const getNavigationHelperText = (
  pathname: string,
  currentLeagueName?: string,
): string | undefined => {
  // if (pathname.includes(RoutesEnum.play)) {
  //   return 'Make Your Picks';
  // }
  //
  // if (pathname.includes(RoutesEnum.pickemsLeaderboard)) {
  //   return 'Leaderboard';
  // }
  //
  // if (pathname.includes(RoutesEnum.view)) {
  //   return 'My Picks';
  // }
  //
  // if (pathname === RoutesEnum.createLeague) {
  //   return 'Create a league';
  // }
  //
  // if (pathname === RoutesEnum.joinLeague) {
  //   return 'Join a league';
  // }
  //
  // if (pathname.includes(RoutesEnum.friendsLeagueShareCode)) {
  //   return 'Create a league';
  // }
  //
  // if (pathname.includes(RoutesEnum.friendsLeaderboard)) {
  //   return currentLeagueName?.toUpperCase();
  // }

  if (pathname.includes(RoutesEnum.rules)) {
    return 'Game Rules';
  }

  if (pathname === RoutesEnum.complete) {
    return 'Complete your profile';
  }
};
