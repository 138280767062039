import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/store';

export interface IStreakSliceState {
  streak: number;
}

const initialState = {
  streak: 0,
} as IStreakSliceState;

const streakSlice = createSlice({
  name: 'streak',
  initialState,
  reducers: {
    SET_STREAK(state, action) {
      state.streak = action.payload;
    },
  },
});

export const selectStreak = (state: RootState) => state.streak;

export default streakSlice.reducer;
export const { SET_STREAK } = streakSlice.actions;
