import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import colors from '@/theme/foundations/colors';

import { trackEvent } from '@/utils/analytics';
import { GAMEZONE_URL } from '@/utils/constants';

export const GamezoneButton = () => {
  const handleClick = () => {
    trackEvent({
      button_text: 'Gamezone',
      location: 'Streaks Navigation',
      action: "User clicks the 'Gamezone' button",
      event: 'button_clicked',
    });
  };

  return (
    <Button
      as={Link}
      color={colors.PrimaryDarkPurple}
      onClick={handleClick}
      to={GAMEZONE_URL}
    >
      Gamezone
    </Button>
  );
};
