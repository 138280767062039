import { FC } from 'react';

import { Image, ImageProps } from '@chakra-ui/react';

import logo from '@/assets/images/logo.png';
import logoPurple from '@/assets/images/logo_purple.png';

type Props = ImageProps & {
  isSidebar?: boolean;
};

export const Logo: FC<Props> = (props) => {
  const { isSidebar } = props;

  return <Image h='80px' src={isSidebar ? logoPurple : logo} {...props} />;
};
