import { useMemo } from 'react';

import { useMediaQuery } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { enabledLanguages } from '@/i18n';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';

import {
  selectSidebar,
  SET_SIDEBAR_CLOSE,
  SET_SIDEBAR_OPEN,
} from '@/store/slices/sidebar.slice';

export const useSettings = () => {
  const dispatch = useDispatch();
  const [isDesktop, isMobile, isTablet] = useMediaQuery(
    [
      MediaQueriesEnum.isDesktop,
      MediaQueriesEnum.isMobile,
      MediaQueriesEnum.isTablet,
    ],
    {
      ssr: false,
    },
  );
  const { isSidebarOpen } = useSelector(selectSidebar);

  const isLangSwitcherEnabled = useMemo(() => enabledLanguages.length > 1, []);

  return {
    isDesktop,
    isMobile,
    isTablet,
    isLangSwitcherEnabled,
    isSidebarOpen,
    onSidebarClose: () => dispatch(SET_SIDEBAR_CLOSE()),
    onSidebarOpen: () => dispatch(SET_SIDEBAR_OPEN()),
  };
};
