import { useEffect, useRef } from 'react';

export const IframeResizer = () => {
  const iframeTypeRef = useRef<string>('');
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (window.self !== window.top) {
        console.log('----- iframe -----');
        if (typeof e.data === 'object') {
          iframeTypeRef.current = e.data.type;
          e.data.height = document.body.clientHeight;
        }

        console.log('message listener', {
          action: 'resize',
          height: document.body.clientHeight,
          type: iframeTypeRef.current,
        });
        window.parent.postMessage(e.data, '*');
      } else {
        console.log('----- no iframe -----');
      }
    };

    window.addEventListener('message', handleMessage);

    resizeObserverRef.current = new ResizeObserver((entries) => {
      console.log('resizeObserver', iframeTypeRef.current);
      if (!iframeTypeRef.current) {
        return;
      }

      console.log('message', {
        action: 'resize',
        height: entries[0].target.clientHeight,
        type: iframeTypeRef.current,
      });
      window.parent.postMessage(
        {
          action: 'resize',
          height: entries[0].target.clientHeight,
          type: iframeTypeRef.current,
        },
        '*',
      );
    });

    resizeObserverRef.current.observe(document.body);

    return () => {
      window.removeEventListener('message', handleMessage);
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, []);

  return null;
};
