import React, { PropsWithChildren, ReactNode, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { TOKEN_LOGOUT } from '@/utils';

import { PageLoader } from '@/elements/PageLoader';

import { SearchParamsEnum } from '@/enums/searchParams.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import useGoogleAnalytics from '@/hooks/GoogleAnalytics/useGoogleAnalytics';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useNavLinks } from '@/hooks/useNavLinks.hook';

import { useGenerateJWTTokenMutation } from '@/services/api.service';

import { SET_IS_JWT_LOADING } from '@/store/slices/pickem.slice';

import { FEATURE_NON_LOGIN_ENABLED } from '@/utils/constants';

export const AuthWrapper: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const dispatch = useDispatch();

  const { linkToGZ } = useNavLinks();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { i18n } = useTranslation();
  const {
    value: jwt,
    setItem: setJWT,
    removeItem,
  } = useLocalStorage<string>(StorageKeysEnum.jwt);
  const { setItem: setToken } = useLocalStorage<string>(StorageKeysEnum.token);
  const { setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );

  const { setItem: setFavouriteTeamId, removeItem: removeFavouriteTeamId } =
    useLocalStorage<number>(StorageKeysEnum.favourite_team_league_id);

  useGoogleAnalytics();

  const token = useMemo(
    () => searchParams.get(SearchParamsEnum.token),
    [searchParams],
  );
  const lang = useMemo(
    () => searchParams.get(SearchParamsEnum.lang),
    [searchParams],
  );

  const [triggerLogin, { isLoading }] = useGenerateJWTTokenMutation();

  useEffect(() => {
    if (!FEATURE_NON_LOGIN_ENABLED && !token && !jwt) {
      window.location.assign(linkToGZ);
    }
  }, [jwt, token, linkToGZ]);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang, () => setLang(lang));
      searchParams.delete(SearchParamsEnum.lang);

      navigate(
        {
          search: searchParams.toString(),
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line
  }, [i18n, lang, navigate, searchParams, setLang, setSearchParams]);

  useEffect(() => {
    const handleLogin = async () => {
      if (!token) return;

      try {
        const res = await triggerLogin({ token }).unwrap();
        setJWT(res.token);
        setToken(token);
        setFavouriteTeamId(res.favourite_team_league_id);
        searchParams.delete(SearchParamsEnum.token);
        dispatch(SET_IS_JWT_LOADING(false));

        navigate(
          {
            search: searchParams.toString(),
          },
          { replace: true },
        );
      } catch (e) {}
    };

    if (token && token === TOKEN_LOGOUT) {
      searchParams.delete(SearchParamsEnum.token);
      navigate(
        {
          search: searchParams.toString(),
        },
        { replace: true },
      );
      removeItem();
      removeFavouriteTeamId();
    }
    if (token && token !== TOKEN_LOGOUT) {
      removeItem();
      handleLogin();
    } else {
      dispatch(SET_IS_JWT_LOADING(false));
    }
    // eslint-disable-next-line
  }, [searchParams, setSearchParams, token]);

  return (
    <>
      {children}
      {isLoading && <PageLoader />}
    </>
  );
};
