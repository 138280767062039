import React, { Suspense } from 'react';

import { Outlet } from 'react-router-dom';

import { AuthWrapper } from '@/containers/AuthWrapper/AuthWrapper';
import { UserInfoProvider } from '@/providers/UserInfoProvider';

import { GlobalBackground } from '@/components/Common/GlobalBackground';
import { HowToPlayModalProvider } from '@/components/HowToPlayModal/HowToPlayModalContext';

import { LocalStorageProvider } from '@/contexts/localStorage.context';

import { PageLoader } from '@/elements/PageLoader';

const AppLayout: React.FC = () => {
  return (
    <LocalStorageProvider>
      <GlobalBackground>
        <AuthWrapper>
          <UserInfoProvider>
            <HowToPlayModalProvider>
              <Suspense fallback={<PageLoader />}>
                <Outlet />
              </Suspense>
            </HowToPlayModalProvider>
          </UserInfoProvider>
        </AuthWrapper>
      </GlobalBackground>
    </LocalStorageProvider>
  );
};

export default AppLayout;
